.typing-indicator {
  will-change: transform;
  width: auto;
  border-radius: 50px;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 14px;
  top: 4px;
  background-color: #ffffff;
  span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
