:root {
  --main-view-header-height: 40px;
}

.main-view-header {
  //box-shadow: 0 1px #E4E4E5;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .main-view-header-right-items {
    div.dropdown {
      height: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 0.75rem !important;
  }
}

.main-view-content {
  //height: calc(100vh - var(--main-view-header-height) - 20px);
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  width: 60%;
  overflow: hidden;
  padding: 5rem;
  height: 100%;

  @media screen and (max-width: 768px) {
    padding: 5rem 0.75rem !important;
    width: 90%;
  }
}
