.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  //.floating-label {
  //    display: inline;
  //}
  .floating-placeholder {
    color: transparent !important;
  }
}

//.form-floating > .form-control:focus ~ label {
//    //.floating-placeholder {
//    //    color: #;
//    //}
//
//    .floating-label {
//        display: inline;
//    }
//
//}

.form-floating > .form-control:not(:placeholder-shown) ~ label {
  .floating-label {
    display: inline !important;
  }

}

.form-floating > .form-control:not(:focus) ~ label {
  .floating-label {
    display: none;
  }

}