.mr-0 {
  margin-right: 0 !important
}

.mr-1 {
  margin-right: .375rem !important
}

.mr-2 {
  margin-right: .75rem !important
}

.mr-3 {
  margin-right: 1.5rem !important
}

.mr-4 {
  margin-right: 2.25rem !important;
}

.mr-5 {
  margin-right: 4.5rem !important;
}

.ml-0 {
  margin-left: 0 !important
}

.ml-1 {
  margin-left: .375rem !important
}

.ml-2 {
  margin-left: .75rem !important
}

.ml-3 {
  margin-left: 1.5rem !important
}

.ml-4 {
  margin-left: 2.25rem !important;
}

.ml-5 {
  margin-left: 4.5rem !important;
}


.pl-0 {
  padding-left: 0 !important
}

.pl-1 {
  padding-left: .375rem !important
}

.pl-2 {
  padding-left: .75rem !important
}

.pl-3 {
  padding-left: 1.5rem !important
}

.pl-4 {
  padding-left: 2.25rem !important;
}

.pl-5 {
  padding-left: 4.5rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: .375rem !important
}

.pr-2 {
  padding-right: .75rem !important
}

.pr-3 {
  padding-right: 1.5rem !important
}

.pr-4 {
  padding-right: 2.25rem !important;
}

.pr-5 {
  padding-right: 4.5rem !important;
}

.btn-rounded {
  border-radius: 50rem !important;
}

.modal-backdrop {
  z-index: 1055;
}

//.btn-info {
//  color: #537df1;
//
//  &:hover {
//    color: #537df1 !important;
//  }
//
//  &:active {
//    color: #537df1 !important;
//  }
//
//  &:focus {
//    color: #537df1 !important;
//  }
//}
//
//.btn-outline-info {
//  color: #537df1;
//  border: 1px solid #eaeaea;
//
//  &:hover {
//    color: #537df1;
//  }
//
//  &:active {
//    color: #537df1 !important;
//  }
//
//  &:focus {
//    color: #537df1 !important;
//  }
//}

//.btn-secondary {
//  color: #ffffff;
//
//  &:hover {
//    color: #ffffff;
//  }
//}
//
//.btn-outline-secondary {
//  color: #505050;
//
//  &:hover {
//    //background-color: var(--secondary);
//    color: #505050;
//  }
//}