:root {
  --menu-background-color: #fff;
  --menu-border-color: #bbb;
  --menu-text-color: #98a6ad;
  //--menu-active-background-color: #ffffff;
  --menu-active-background-color: #ffffff;
  --menu-active-color: #555555;
  //--left-container-background-color: #fafbfc;
  --left-container-background-color: #f4f5f7;
  --left-container-text-color: #42526e;
  --app-nav-bar-height: 32px;
  --live-chat-container-width: 190px;
  //--panel-box-shadow: 0 0 1px 0 rgba(212, 213, 215, 0.8);
  --panel-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
  //--panel-box-shadow: 0 0 1px 0 #d4d5d7;
  //--live-chat-pannel-boder: 1px solid rgba(0, 0, 0, 0.3);

  --color-muted: #98a6ad;
  --message-border-color: #98a6ad;
  --live-chat-info-width: 400px;
  --message-container-width: calc(100vw - 64px - var(--live-chat-container-width) - 240px - var(--live-chat-info-width));
  --message-content-width: calc(var(--message-container-width) - 74px - 10px);
  --live-chat-top-height: 60px;
  --live-chat-bottom-height: 60px;
  --media-message-border-color: var(--ct-light);

  --chat-list-active-color: #e0e0e0;
}

html {
  height: 100%;
  -webkit-text-size-adjust: none;
}

//html {
//  height: -webkit-fill-available;
//}

body {
  background-color: #f4f5f7 !important;
  //background-color: #ffffff !important;
  //margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Noto Sans KR", "Nunito", sans-serif !important;
  height: 100%;
  //min-height: 100vh;
  //min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;

  #root {
    height: 100%;
    overflow: hidden;
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  //height: 100vh;
  //height: -webkit-fill-available;
  height: 100%;
  width: 100vw;
  overflow: hidden;

  .app-content-wrapper {
    //background-color: #f4f5f7;
    //background-color: #ffffff;
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    overflow: hidden;
    height: 100%;
    position: relative;
    //width: 100%;
  }
}

.draggable {
  -webkit-app-region: drag !important;
}

.no-draggable {
  -webkit-app-region: no-drag !important;
}

.bg-profile {
  background-color: #313a46 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-mono {
  font-family: "Noto Sans KR", monospace;;
}

@keyframes remove-chats {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 100%;
  }
}

.no-style-textarea {
  outline: none;
  border: none !important;
  resize: none;

  &:focus {
    border: none !important;
    outline: none;
    box-shadow: none;
  }
}

.btn-link {
  &:focus {
    box-shadow: none !important;
  }
}

.__react_component_tooltip {
  .multi-line {
    text-align: left !important;
  }
}

//@media (max-width: 767.98px) {
//  .content-page {
//    margin-left: var(--menu-width) !important;
//    padding: 0;
//  }
//}
//
//.content-page {
//  margin-left: var(--menu-width) !important;
//  padding: 0 !important;
//
//  .content {
//    .container-fluid {
//      padding: 0 !important;
//
//      .left-container {
//        display: flex;
//        flex-direction: column;
//        flex-grow: 0;
//        flex-shrink: 0;
//        background-color: var(--menu-background-color);
//        border-left: 1px solid var(--menu-border-color);
//        height: 100vh;
//        box-shadow: 0 0 10px 0 rgba(154,161,171,.6);
//      }
//    }
//  }
//}


//::-webkit-scrollbar {
//  width: 10px;
//}


//::-webkit-resizer,
//::-webkit-scrollbar-button,
//::-webkit-scrollbar-corner { display: none; }


audio {
  background-color: #f1f3f4;
  border: none;
  border-radius: 0.25rem;
  //&::-webkit-media-controls-panel {
  //}
}

*[data-floating-toolbar="true"] {
  position: relative;
  &:hover {
    .floating-toolbar {
      display: block;
    }
  }
}


//span[data-slate-placeholder="true"] {
//  font-size: 13px;
//}

span[data-slate-node="text"] {
  font-size: 14px;
}

.text-pre {
  white-space: pre-wrap;
}

//* ::-webkit-scrollbar-thumb {
//  background-color: blue;
//}
//
//* ::-webkit-scrollbar {
//  width: 15px;
//
//  &:hover {
//    width: 30px;
//  }
//}
//
//* ::-webkit-scrollbar-track {
//  background-color: red;
//}

.height-transition {
  transition: height 0.1s ease-in-out;
}